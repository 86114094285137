import { axios } from '@/utils/request'

/**
 * 分页查询角色信息表
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function page (parameter) {
    return axios({
        url: '/role/list',
        method: 'get',
        params: parameter
    })
}

/**
 * 增加角色信息表
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function add (parameter) {
    return axios({
        url: '/role/add',
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑角色信息表
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function edit (parameter) {
    return axios({
        url: '/role/edit',
        method: 'post',
        data: parameter
    })
}

/**
 * 查看详情角色信息表
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function detail (parameter) {
    return axios({
        url: '/acRole/detail',
        method: 'get',
        params: parameter
    })
}

/**
 * 删除角色信息表
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function del (parameter) {
    return axios({
        url: '/role/del',
        method: 'post',
        data: parameter
    })
}
/**
 * 获取角色菜单权限
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function listByRole (parameter) {
  return axios({
    url: '/menu/listByRole',
    method: 'get',
    params: parameter
  })
}
/**
 * 获取角色菜单权限
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function menuAccredit (parameter) {
  return axios({
    url: '/role/menuAccredit',
    method: 'post',
    data: parameter
  })
}
/**
 * 获取角色数据权限
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function getDeptByRoleId (parameter) {
  return axios({
    url: '/dept/getDeptByRoleId',
    method: 'get',
    params: parameter
  })
}
/**
 * 获取角色数据权限
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function dataAccredit (parameter) {
  return axios({
    url: '/role/dataAccredit',
    method: 'post',
    data: parameter
  })
}
/**
 * 获取角色数据权限
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function getOne (parameter) {
  return axios({
    url: '/role/getOne',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取角色用户配置
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function getRoleUserConfig (parameter) {
  return axios({
    url: '/role/get_role_user_config',
    method: 'get',
    params: parameter
  })
}

/**
 * 调整角色用户
 *
 * @author taidi
 * @date 2021/02/25 11:58
 */
export function adjustRoleUser (parameter) {
  return axios({
    url: '/role/adjust_role_user',
    method: 'post',
    data: parameter
  })
}