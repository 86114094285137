<template>
  <a-modal
    title="菜单授权"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">
    <a-spin :spinning="loading">

      <a-tree
        ref="tree"
        default-expand-all
        :check-strictly='true'
        checkable
        :key="treeDataKey"
        v-model="currentIds"
        :tree-data="treeData"
        :replaceFields="{children:'children', title:'name', key:'key' }"
      >
        <div slot="nodeData" slot-scope="record" :class="record.key+'1'" :style="setTreeDom(record)">
          <div>{{ record.name }}</div>
        </div>
      </a-tree>

    </a-spin>
  </a-modal>
</template>

<script>
import { listByRole, menuAccredit } from '@/api/user-rights/rolemanage'
import {getDeptMenuConfig, saveDeptMenuConfig} from "@/api/user-rights/organizemanage";

export default {
  name: 'acDeptMenuAuthorize',
  data () {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      treeData: [],
      currentIds: { checked: [] }, // 选中信息
      treeDataKey: 1,
      deptId: null
    }
  },
  methods: {
    edit (record) {
      this.deptId = record.deptId
      this.handleMenuTree()
      this.visible = true
    },
    handleCancel () {
      this.roleId = null
      this.currentIds = { checked: [] }
      this.visible = false
    },
    handleSubmit () {
      const data = {
        deptId: this.deptId,
        menuSelectedList: this.currentIds.checked.map(val => {
          val = {
            menuId: val,
            // 是否可向下级分配 0-否 1-是
            toChild: 1
          }
          return val
        })
      }
      saveDeptMenuConfig(data).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('编辑成功')
          this.handleCancel()
          this.$emit('ok')
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },
    /**
     * 获取角色菜单权限
     */
    handleMenuTree () {
      this.loading = true
      getDeptMenuConfig({ deptId: this.deptId }).then((res) => {
        if (res.code === 200) {
          console.log(res, 'res1')
          this.recursion(res.data['menuTrees'])
          this.treeData = res.data['menuTrees']

          this.currentIds.checked = res.data['menuSelectedList'].map(val => {
            val = val.menuId
            return val
          })
          this.treeDataKey++
        }
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 设置树dom样式
     */
    setTreeDom (record) {
      if (record.children) {
        this.$nextTick(() => {
          const thisDomElement = document.getElementsByClassName(record.key + '1')[0].parentNode.parentNode.parentNode
          thisDomElement.classList.add('line')
        })
      }
    },
    /**
     * 递归处理信息
     */
    recursion (list) {
      const children = []
      for (let i = list.length-1; i >= 0; i--) {
        const item = list[i]
        item.scopedSlots = { title: 'nodeData' }
        if (i === 0) {
          let child = children.length
          for (let j = 0; j < child; j++) {
            list.push(children.pop())
          }
        } else if (item.children) {
          children.push(item)
          list.splice(i, 1)
        }
      }
      _(list).forEach((item, index) => {
        if (item.children) {
          this.recursion(item.children)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/modal";

/deep/ .ant-tree-treenode-switcher-open {
  display: inline-block;

}

/deep/ .line {
  display: block !important;
}

/deep/ .ant-tree li ul {
  //word-wrap: break-word;
  word-wrap: normal;
  //word-break: normal;
  word-break: keep-all;
  width: 100%;
}

/deep/ .ant-tree li {
  white-space: unset !important;
}
</style>
